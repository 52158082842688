<template>
  <div v-if="!isFetching" class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment_ind</md-icon>
          </div>
          <h4 class="title">Tabela de Logs das Atividades dos Usuários</h4>
        </md-card-header>
        <md-card-content>
          <p></p>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por pagina</label>
                <md-select
                  v-model="pagination.perPage"
                  name="pages"
                  style="width: 100px"
                >
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <!--<span class="space-tool-bar"></span>
              <md-field>
                <label for="filtro">Filtrar por Entidade</label>
                <md-select
                  v-if="!isFetching2"
                  v-model="filtroEntidade"
                  name="filtroEntidade"
                  @md-selected="onChangeEntidade"
                >
                  <md-option
                    v-for="item in entidadeFilter"
                    :key="item.idEntidade"
                    :label="item.nomeEntidade"
                    :value="item.nomeEntidade"
                  >
                    {{ item.nomeEntidade }}
                  </md-option>
                </md-select>
              </md-field>
              -->
              <span class="space-tool-bar"></span>
              <md-field>
                <label for="filtroAtividade">Filtrar por Atividade</label>
                <md-select
                  v-if="!isFetching"
                  v-model="filtroAtividade"
                  name="filtroAtividade"
                  @md-selected="onChangeFiltroAtividade"
                  style="width: 150px"
                >
                  <md-option
                    v-for="item in atividadeFilter"
                    :key="item.idAtividade"
                    :label="item.atividade"
                    :value="item.atividade"
                  >
                    {{ item.atividade }}
                  </md-option>
                </md-select>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-field>
                <label for="filtroPapel">Filtrar por Papel</label>
                <md-select
                  v-if="!isFetching"
                  v-model="filtroPapel"
                  name="filtroPapel"
                  @md-selected="onChangeFiltroPapel"
                  style="width: 150px"
                >
                  <md-option
                    v-for="item in papelFilter"
                    :key="item.idPapel"
                    :label="item.nomePapel"
                    :value="item.nomePapel"
                  >
                    {{ item.nomePapel }}
                  </md-option>
                </md-select>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 150px"
                  placeholder="Nome Usuario"
                  v-model="searchQueryNomeUsuario"
                >
                </md-input>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Email"
                  v-model="searchQueryEmail"
                >
                </md-input>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Rota"
                  v-model="searchQueryRota"
                >
                </md-input>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Destino"
                  v-model="searchQueryDestino"
                >
                </md-input>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-datepicker v-model="searchQueryDataAtividade">
                <label>Data da Atividade</label></md-datepicker
              >

              <!--<md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Data Atividade"
                  v-model="searchQueryDataAtividade"
                >
                </md-input>
              </md-field>-->
              <div class="md-toolbar-section-start">
                <md-button
                  class="size35 md-just-icon md-simple md-success"
                  @click.native="handleSearch()"
                >
                  <md-icon>search</md-icon>
                </md-button>
              </div>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                md-label="Nome de Usuario"
                md-sort-by="nomeusuario"
                >{{ item.nomeusuario }}</md-table-cell
              >
              <md-table-cell md-label="Email" md-sort-by="email">{{
                item.email
              }}</md-table-cell>
              <md-table-cell md-label="Rota" md-sort-by="rota">{{
                item.rota
              }}</md-table-cell>
              <md-table-cell md-label="Destino" md-sort-by="destino">{{
                item.destino
              }}</md-table-cell>
              <md-table-cell md-label="Atividade" md-sort-by="atividade">{{
                item.atividade
              }}</md-table-cell>
              <md-table-cell md-label="Papel" md-sort-by="papel">{{
                item.papel
              }}</md-table-cell>
              <md-table-cell
                md-label="Data Atividade"
                md-sort-by="dataAtividade"
                >{{ item.dataAtividade }}</md-table-cell
              >
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.idLogAtividade"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Mostrando {{ from + 1 }} até {{ to }} de {{ total }} registros
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { Pagination } from "@/components";
import Swal from "sweetalert2";
//import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { siapeServidorMask } from "@/pages/Dashboard/js/siapeMask";
import format from "date-fns/format";

var now = new Date();
var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  components: {
    Pagination
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    user_profile_state: state => state.user_profile,
    dateFormat: {
      get() {
        return this.$material.locale.dateFormat;
      },
      set(val) {
        this.$material.locale.days = [
          "Domingo",
          "Segunda",
          "Terça",
          "Quarta",
          "Quinta",
          "Sexta",
          "Sábado"
        ];
        this.$material.locale.shortDays = [
          "Dom",
          "Seg",
          "Ter",
          "Qua",
          "Qui",
          "Sex",
          "Sáb"
        ];
        this.$material.locale.shorterDays = ["D", "S", "T", "Q", "Q", "S", "S"];
        this.$material.locale.months = [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ];
        this.$material.locale.shortMonths = [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez"
        ];

        this.$material.locale.dateFormat = val;
      }
    },
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.tableData.length > 0) {
        return result;
      } else {
        return [];
      }
    },
    to() {
      if (this.totalRegistros === 0) {
        return 0;
      } else {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      }
    },
    from() {
      if (this.totalRegistros === 0) {
        return -1;
      } else {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    total() {
      return this.totalRegistros;
    },
    reload: function() {
      this.getPapelAll();
      //this.getEntidadeAll();
      this.getLogAtividadesPagination();
      return null;
    }
  },

  data() {
    return {
      // selectedDate: null,
      mensagem: "",
      isFetching: true,
      isFetching2: true,
      isFetching3: true,
      papel: "",
      currentSort: "dataAtividade",
      currentSortOrder: "desc",
      entidadeFilter: [],
      filtroEntidade: "",
      filtroPapel: null,
      papelFilter: [],
      filtroAtividade: null,
      atividadeFilter: [
        { idAtividade: 0, atividade: "" },
        { idAtividade: 1, atividade: "ACESSO" },
        { idAtividade: 2, atividade: "DOWNLOAD" }
      ],
      totalRegistros: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 200, 500, 1000],
        total: 0
      },
      footerTable: [
        "Nome Usuario",
        "Email",
        "Rota",
        "Destino",
        "Atividade",
        "Papel",
        "Data Atividade"
      ],
      searchQueryNomeUsuario: null,
      searchQueryEmail: null,
      searchQueryRota: null,
      searchQueryDestino: null,
      //searchQueryPapel: null,
      searchQueryDataAtividade: null,
      //idEntidadeSelected: null,
      atividadeSelected: null,
      papelSelected: null,
      tableData: []
    };
  },
  beforeMount() {
    this.getPapel();
    this.getPapelAll();
    //this.getEntidadeAll();
    this.getLogAtividadesPagination();
    this.dateFormat = "dd/MM/yyyy";
  },
  methods: {
    ...mapActions("log_atividades", [
      "ActionGetLogAtividadesPagination",
      "ActionGetLogAtividadesByQuery"
    ]),
    ...mapActions("papel", ["ActionGetPapelAll"]),
    //...mapActions("entidade", ["ActionGetEntidadeAll"]),

    checkPapel() {
      return this.papel === "ADMIN" ? true : false;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    /*callCpfMask(value) {
      return cpfMask(value.toString());
    },*/
    checkAssingmentDisabled(item) {
      if (item.nomePapel === this.papelSelected.nomePapelSelected) {
        return "disabled";
      } else {
        return null;
      }
    },
    checkAssingmentStatus(item) {
      if (item.nomePapel === this.papelSelected.nomePapelSelected) {
        return "md-success";
      } else {
        return "md-info";
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    async getLogAtividadesPagination() {
      try {
        let papel = this.papel;
        let noOfRecords = this.pagination.perPage;
        let pageIndex = this.pagination.currentPage;
        let idUsuario = this.user_profile_state.idUsuario;
        await this.ActionGetLogAtividadesPagination({
          papel,
          idUsuario,
          noOfRecords,
          pageIndex
        }).then(response => {
          if (response.status === 200) {
            if (response.body.mensagem && response.body.mensagem !== null) {
              console.log(response);
              this.tableData = [];
              this.totalRegistros = 0;
            } else {
              this.tableData = response.body.log_atividades;
              this.totalRegistros = response.body.totalRegistros;
            }
          }
          this.isFetching2 = false;
          this.isFetching3 = false;
          this.isFetching = false;
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getLogAtividadesPaginationNext() {
      try {
        let papel = this.papel;
        let noOfRecords = this.pagination.perPage;
        let pageIndex = this.pagination.currentPage;
        let idUsuario = this.user_profile_state.idUsuario;
        let response = await this.ActionGetLogAtividadesPagination({
          papel,
          idUsuario,
          noOfRecords,
          pageIndex
        }).then(res => {
          //console.log(res);
          this.tableData = res.body.log_atividades;
        });
      } catch (err) {
        //console.log(err);
        this.handleError(err);
      }
    },
    async getPapelAll() {
      try {
        let papel = this.papel;
        await this.ActionGetPapelAll({ papel }).then(response => {
          if (response.status === 200) {
            //console.log(response.body);
            this.papelFilter = response.body;
            let papelBranco = {
              idPapel: "",
              nomePapel: ""
            };
            this.papelFilter.push(Vue.util.extend({}, papelBranco));

            this.papelFilter.sort(function(a, b) {
              return a.nomePapel < b.nomePapel
                ? -1
                : a.nomePapel > b.nomePapel
                ? 1
                : 0;
            });
          }
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    /* async getEntidadeAll() {
      try {
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;
        let response = await this.ActionGetEntidadeAll({ papel, idEntidade });
        if (this.papel === "ADMIN") {
          let entidadeFilter = response.body;
          entidadeFilter.push({
            nomeEntidade: ""
          });
          entidadeFilter.sort((a, b) => {
            if (a.nomeEntidade < b.nomeEntidade) return -1;
            if (a.nomeEntidade > b.nomeEntidade) return 1;
            return 0;
          });
          this.entidadeFilter = entidadeFilter;
        } else {
          this.entidadeFilter = [];
          this.entidadeFilter.push({
            nomeEntidade: this.user_profile_state.nomeEntidade
          });
          this.filtroEntidade = this.user_profile_state.nomeEntidade;
        }
        return response.body;
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    */
    handleError(err) {
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    async handleSearch() {
      if (
        (this.atividadeSelected === "" || this.atividadeSelected === null) &&
        (this.searchQueryNomeUsuario === "" ||
          this.searchQueryNomeUsuario === null) &&
        (this.searchQueryEmail === "" || this.searchQueryEmail === null) &&
        (this.searchQueryRota === "" || this.searchQueryRota === null) &&
        (this.searchQueryDestino === "" || this.searchQueryDestino === null) &&
        (this.searchQueryDataAtividade === "" ||
          this.searchQueryDataAtividade === null) &&
        (this.papelSelected === "" || this.papelSelected === null)
      ) {
        this.getLogAtividadesPagination();
      } else {
        let papel = this.papel;
        let atividade = null;
        let nomeusuario = null;
        let email = null;
        let rota = null;
        let destino = null;
        let papel_selected = null;
        let data_atividade = null;
        let idUsuario = this.user_profile_state.idUsuario;
        /*
      searchQueryNomeUsuario: null,
      searchQueryEmail: null,
      searchQueryRota: null,
      searchQueryDestino: null,
      searchQueryDataAtividade: null,      
      atividadeSelected: null,
      papelSelected: null,
      */

        if (this.atividadeSelected !== "" && this.atividadeSelected !== null) {
          atividade = this.atividadeSelected;
        }
        if (
          this.searchQueryNomeUsuario !== "" &&
          this.searchQueryNomeUsuario !== null
        ) {
          nomeusuario = this.searchQueryNomeUsuario;
        }
        if (this.searchQueryEmail !== "" && this.searchQueryEmail !== null) {
          email = this.searchQueryEmail;
        }
        if (this.searchQueryRota !== "" && this.searchQueryRota !== null) {
          rota = this.searchQueryRota;
        }
        if (
          this.searchQueryDestino !== "" &&
          this.searchQueryDestino !== null
        ) {
          destino = this.searchQueryDestino;
        }
        if (this.papelSelected !== "" && this.papelSelected !== null) {
          papel_selected = this.papelSelected;
        }
        if (
          this.searchQueryDataAtividade !== "" &&
          this.searchQueryDataAtividade !== null
        ) {
          data_atividade = this.searchQueryDataAtividade;
          data_atividade = format(data_atividade, this.dateFormat);
        }
        let noOfRecords = this.pagination.perPage;
        let pageIndex = this.pagination.currentPage;
        await this.ActionGetLogAtividadesByQuery({
          papel,
          idUsuario,
          atividade,
          nomeusuario,
          email,
          rota,
          destino,
          papel_selected,
          data_atividade,
          noOfRecords,
          pageIndex
        }).then(response => {
          if (response.body.mensagem) {
            if (response.body.mensagem.includes("não encontrados")) {
              this.totalRegistros = 0;
              this.tableData = [
                {
                  idLogAtividade: "",
                  idUsuario: "",
                  nomeusuario: "",
                  email: "",
                  rota: "",
                  destino: "",
                  atividade: "",
                  papel: "",
                  dataAtividade: ""
                }
              ];
            }
          } else {
            this.tableData = response.body.log_atividades;
            this.totalRegistros = response.body.totalRegistros;
          }
        });
      }
    },
    onChangeFiltroAtividade: function() {
      if (this.filtroAtividade !== "") {
        let result = this.tableData;
        let nomeAtividade = this.filtroAtividade;
        let index = -1;
        let filteredResult = this.atividadeFilter.find(function(item, i) {
          if (item.atividade === nomeAtividade) {
            index = i;
            return item;
          }
        });
        let atividade = filteredResult.atividade;
        this.atividadeSelected = atividade;
      } else {
        this.filtroAtividade = "";
        this.atividadeSelected = "";
      }
    },
    onChangeFiltroPapel: function() {
      if (this.filtroPapel !== "") {
        let result = this.tableData;
        let nomePapel = this.filtroPapel;
        let index = -1;
        let filteredResult = this.papelFilter.find(function(item, i) {
          if (item.nomePapel === nomePapel) {
            index = i;
            return item;
          }
        });
        let papel = filteredResult.nomePapel;
        this.papelSelected = papel;
      } else {
        this.filtroPapel = "";
        this.papelSelected = "";
      }
    }
    /*onChangeEntidade: async function() {
      if (this.filtroEntidade !== "") {
        let result = this.tableData;
        let nomeEntidade = this.filtroEntidade;
        let index = -1;
        let filteredResult = this.entidadeFilter.find(function(item, i) {
          if (item.nomeEntidade === nomeEntidade) {
            index = i;
            return item;
          }
        });
        let idEntidade = filteredResult.idEntidade;
        this.idEntidadeSelected = idEntidade;
      } else {
        this.filtroEntidade = "";
        this.idEntidadeSelected = "";
      }
    }*/
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        if (
          (this.atividadeSelected === "" || this.atividadeSelected === null) &&
          (this.searchQueryNomeUsuario === "" ||
            this.searchQueryNomeUsuario === null) &&
          (this.searchQueryEmail === "" || this.searchQueryEmail === null) &&
          (this.searchQueryRota === "" || this.searchQueryRota === null) &&
          (this.searchQueryDestino === "" ||
            this.searchQueryDestino === null) &&
          (this.searchQueryDataAtividade === "" ||
            this.searchQueryDataAtividade === null) &&
          (this.papelSelected === "" || this.papelSelected === null)
        ) {
          this.getLogAtividadesPaginationNext();
        } else {
          this.handleSearch();
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.8rem;
  font-weight: 400;
}

.footer-table .md-table-head-label {
  font-size: 0.8rem;
  font-weight: bold;
}
.md-table-cell {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.74rem;
}
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 30px;
  height: 20px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}

.size35 i {
  font-size: 35px !important;
}

::v-deep .md-toolbar-section-start {
  padding-bottom: 20px;
}

::v-deep .md-card .md-card-content {
  padding: 2px 20px;
}

::v-deep .paginated-table table > tbody > tr > td {
  max-width: 250px;
  width: 200;
}
</style>
